import React from "react";
import * as styles from "./product.module.scss";
import PageLayout from "../components/layout/PageLayout";
import Masthead from "../components/layout/Masthead";
import Seo from "../components/layout/SEO";
import Intro from "../components/layout/Intro";
import ScrollReveal from "../components/layout/ScrollReveal";
import ProductWebsite from "../components/product/ProductWebsite";
import { externalURL } from "../config/url";
import ProductDownload from "../components/product/ProductDownload";
import ProductScreenshot from "../components/product/ProductScreenshot";
import ProductTab from "../components/product/ProductTab";
import ProductPrivacy from "../components/product/ProductPrivacy";
import ProductCta from "../components/product/ProductCta";

import productAnim from "../assets/json/product.json";

const product = () => {
  return (
    <PageLayout includeCTA anim={productAnim}>
      <Seo title="Product - Simplicitic Innovations Sdn Bhd" />
      <Masthead title="Comprehensive Booking System" tag="product" />
      <Intro>
        <div className={`text-center ${styles["intro"]}`}>
          <ScrollReveal effect="slide-up" delay={50}>
            <p className="h4">
              Simplicitic's very own application, AddPointment, is a
              hassle-free, comprehensive online booking management system that
              tracks all your appointments, reservations and bookings. The app
              solves service providers' challenges in juggling schedule
              maintenance and customer bookings organisation.
            </p>
            <a
              href={externalURL.addpointment}
              target="_blank"
              rel="noreferrer"
              className="btn"
            >
              Find out more
            </a>
          </ScrollReveal>
        </div>
      </Intro>
      <ProductWebsite />
      <ProductCta
        className="h3"
        text="An online booking system built from the ground up that safeguards
              all personal data."
      />
      <ProductDownload />
      <ProductScreenshot />
      <ProductCta
        className="h4"
        text="A convenient online booking system fits any industry—making it
        efficient for you and your customers. It keeps waiting time short
        and allows you to manage available slots easily."
      />
      <ProductTab />
      <ProductPrivacy />
      <ProductCta
        className="h3"
        text="It's time to take your bookings to the next level"
      />
    </PageLayout>
  );
};

export default product;
