import React, { useState } from "react"; //, useRef, useEffect
import classNames from "classnames";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/all";

import * as styles from "./ProductTab.module.scss";

import Btn from "../../images/arrow-blue.svg";
import Restaurant from "../../images/restaurant-icon.svg";
import FBOutlet from "../../images/fb-outlet-icon.svg";
import FoodCourt from "../../images/foodcourt-icon.svg";
import TakeAway from "../../images/takeaway-icon.svg";
import Chiropractic from "../../images/chiropractic-icon.svg";
import Pharmacy from "../../images/pharmacy-icon.svg";
import Doctor from "../../images/doctor-icon.svg";
import Dentist from "../../images/dentist-icon.svg";
import Spa from "../../images/spa-icon.svg";
import Stylist from "../../images/stylist-icon.svg";
import Manicure from "../../images/manicure-icon.svg";
import FacialTreatment from "../../images/facial-treatment-icon.svg";
import Coaching from "../../images/coaching-icon.svg";
import Attorney from "../../images/attorney-icon.svg";
import PersonalTrainer from "../../images/personal-trainer-icon.svg";
import FinancialConsultant from "../../images/financial-consultant-icon.svg";
import Mechanic from "../../images/mechanic-icon.svg";
import CarGrooming from "../../images/car-grooming-icon.svg";
import CleaningService from "../../images/cleaning-service-icon.svg";
import HomeRepair from "../../images/home-repair-icon.svg";
import Workshop from "../../images/workshop-icon.svg";
import EventOrganiser from "../../images/event-organiser-icon.svg";
import EducationCentre from "../../images/education-centre-icon.svg";
import Photography from "../../images/photography-icon.svg";
import Engineer from "../../images/engineer-icon.svg";
import PropertyAgent from "../../images/property-agent-icon.svg";
import Accountant from "../../images/accountant-icon.svg";
import Salesperson from "../../images/salesperson-icon.svg";
import PublicService from "../../images/public-service-icon.svg";
import PublicVenues from "../../images/public-venues-icon.svg";
import FacilityManagement from "../../images/facility-management-icon.svg";
import EventSpace from "../../images/event-space-icon.svg";
import { Col, Row } from "../layout/Grid";
import ScrollReveal from "../layout/ScrollReveal";

const iconList = [
  {
    id: 1,
    name: "Food & Beverage",
    icons: [
      { src: Restaurant, name: "Restaurant" },
      { src: FBOutlet, name: "FB Outlet" },
      { src: FoodCourt, name: "Food Court" },
      { src: TakeAway, name: "Take Away" },
    ],
  },
  {
    id: 2,
    name: "Health & Medical",
    icons: [
      { src: Chiropractic, name: "Chiropractic" },
      { src: Pharmacy, name: "Pharmacy" },
      { src: Doctor, name: "Doctor" },
      { src: Dentist, name: "Dentist" },
    ],
  },
  {
    id: 3,
    name: "Beauty & Wellness",
    icons: [
      { src: Spa, name: "Spa" },
      { src: Stylist, name: "Stylist" },
      { src: Manicure, name: "Manicure" },
      { src: FacialTreatment, name: "Facial Treatment" },
    ],
  },
  {
    id: 4,
    name: "Training & Consultation",
    icons: [
      { src: Coaching, name: "Coaching" },
      { src: Attorney, name: "Attorney" },
      { src: PersonalTrainer, name: "Personal Trainer" },
      { src: FinancialConsultant, name: "Financial Consultant" },
    ],
  },
  {
    id: 5,
    name: "Cleaning & Repair",
    icons: [
      { src: Mechanic, name: "Mechanic" },
      { src: CarGrooming, name: "Car Grooming" },
      { src: HomeRepair, name: "Home Repair" },
      { src: CleaningService, name: "Cleaning Service" },
    ],
  },
  {
    id: 6,
    name: "Education & Entertainment",
    icons: [
      { src: Workshop, name: "Workshop" },
      { src: Photography, name: "Photography" },
      { src: EducationCentre, name: "Education Centre" },
      { src: EventOrganiser, name: "Event Organiser" },
    ],
  },
  {
    id: 7,
    name: "Professional Services",
    icons: [
      { src: Engineer, name: "Engineer" },
      { src: Salesperson, name: "Salesperson" },
      { src: PropertyAgent, name: "Property Agent" },
      { src: Accountant, name: "Accountant" },
    ],
  },
  {
    id: 8,
    name: "Public Service",
    icons: [
      { src: PublicService, name: "Public Service" },
      { src: EventSpace, name: "Event Space" },
      { src: PublicVenues, name: "Public Venue" },
      { src: FacilityManagement, name: "Facility Management" },
    ],
  },
];

const ProductTab = () => {
  const [selectedId, setSelectedId] = useState(1);

  const handleTabClicked = (id) => {
    setSelectedId(id);
  };

  const handleClick = (value) => {
    const id = selectedId + value;
    if (id > 8) setSelectedId(1);
    else if (id < 1) setSelectedId(8);
    else setSelectedId(id);
  };

  return (
    <section className="section inner-wrapper">
      <Row className={styles.container}>
        <Col className={styles.nav}>
          <ul>
            {iconList.map((item, index) => {
              return (
                <ScrollReveal
                  effect="slide-up"
                  delay={index * 50 + 50}
                  key={index}
                >
                  <li className={item.id === selectedId ? styles.active : ""}>
                    <button
                      onClick={() => handleTabClicked(item.id)}
                      onKeyDown={() => handleTabClicked(item.id)}
                    >
                      <img src={Btn} alt="arrow"></img>
                      <div className={styles["label"]}>{item.name}</div>
                    </button>
                  </li>
                </ScrollReveal>
              );
            })}
          </ul>
        </Col>
        <div className={styles.mobileNav}>
          <button className={styles.btn} onClick={() => handleClick(-1)}>
            <img src={Btn} alt="Arrow left"></img>
          </button>
          {iconList.map((item, index) => {
            return (
              <h6
                className={classNames(item.id === selectedId && styles.active)}
                key={index}
              >
                {item.name}
              </h6>
            );
          })}

          <button className={styles.btn} onClick={() => handleClick(+1)}>
            <img src={Btn} alt="Arrow left"></img>
          </button>
        </div>
        <Col
          className={styles["icons"]}
          // ref={(el) => {
          //   tabContent = el;
          // }}
        >
          {iconList.map((item, index) => {
            return (
              <ScrollReveal effect="slide-up" delay={50} key={index}>
                <ul
                  className={classNames(
                    item.id === selectedId && styles.active
                  )}
                >
                  {item.icons.map((icon, key) => {
                    return (
                      <li key={key}>
                        <img src={icon.src} alt={icon.name} />
                        <p>{icon.name}</p>
                      </li>
                    );
                  })}
                </ul>
              </ScrollReveal>
            );
          })}
        </Col>
      </Row>
    </section>
  );
};

export default ProductTab;
