import React from "react";
import * as styles from "./ProductScreenshot.module.scss";
import ScrollReveal from "../layout/ScrollReveal";

import screen1 from "../../images/app-screenshot-1.svg";
import screen2 from "../../images/app-screenshot-2.svg";
import screen3 from "../../images/app-screenshot-3.svg";
import screen4 from "../../images/app-screenshot-4.svg";
import screen5 from "../../images/app-screenshot-5.svg";

const images = [screen1, screen2, screen3, screen4, screen5];

const ProductScreenshot = () => {
  return (
    <div className={`section ${styles.container}`}>
      {images.map((item, index) => {
        return (
          <div>
            <ScrollReveal effect="slide-up" delay={index * 50 + 50} key={index}>
              <img src={item} alt="" />
            </ScrollReveal>
          </div>
        );
      })}
    </div>
  );
};
export default ProductScreenshot;
