import React from "react";
import ScrollReveal from "../../components/layout/ScrollReveal";
import { externalURL } from "../../config/url";

import * as styles from "./ProductCta.module.scss";

const ProductCta = ({ text, className }) => {
  return (
    <div className={`section wrapper ${styles.cta}`}>
      <ScrollReveal effect="slide-up" delay={50}>
        <p className={className}>{text}</p>
        <a
          href={externalURL.addpointment}
          target="_blank"
          rel="noreferrer"
          className="btn"
        >
          Find Out More
        </a>
      </ScrollReveal>
    </div>
  );
};

export default ProductCta;
