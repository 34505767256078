import React, { useState } from "react";
import ReactModal from "react-modal";
import Lottie from "lottie-web";
import { Row, Col } from "../../components/layout/Grid";

import * as styles from "./ProductPrivacy.module.scss";

import bannerVideo from "../../assets/json/home-banner.json";
import ScrollReveal from "../layout/ScrollReveal";
import playBtn from "../../images/arrow-white.svg";

ReactModal.setAppElement("#___gatsby");
const ProductPrivacy = () => {
  const [modalOpen, setModalOpen] = useState(false);
  React.useEffect(() => {
    Lottie.loadAnimation({
      container:
        typeof window !== "undefined" && document.querySelector("#app-video"),
      animationData: bannerVideo,
    });
  }, []);

  return (
    <>
      <section className="section wrapper">
        <Row>
          <Col width="35%">
            <ScrollReveal effect="slide-right" delay={50}>
              <h3 className="h2">Keep customer data invisible</h3>
            </ScrollReveal>
          </Col>
          <Col width="50%">
            <ScrollReveal effect="slide-left" delay={50}>
              <p>
                The admin panel allows business owners and staff to manage
                customer bookings securely and privately. Just set up the
                services or available resources for customers to book. Then, the
                service providers can decide if customers are allowed advance
                bookings online or during walk-ins at the store
              </p>
            </ScrollReveal>
          </Col>
        </Row>
      </section>
      <ScrollReveal effect="slide-up" delay={50}>
        <div className={`wrapper ${styles.container}`}>
          <div className={styles.appVideo}>
            <div id="app-video"></div>
            <button onClick={() => setModalOpen(true)} aria-label="button">
              <img src={playBtn} alt="Play Button" />
            </button>
          </div>
          <ReactModal
            closeTimeoutMS={500}
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            className={styles.appModal}
            overlayClassName="app-modal-overlay"
          >
            <button onClick={() => setModalOpen(false)}>
              <div></div>
              <div></div>
            </button>
            <div className="modal-inner">
              <iframe
                src="https://www.youtube.com/embed/DWop-bqcP1s?&autoplay=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </ReactModal>
        </div>
      </ScrollReveal>
    </>
  );
};
export default ProductPrivacy;
